// let apiBaseUrl = 'https://outrighttalk-backend.glohtesting.com/api/v1/';

// let apiBaseUrl = 'https://outrightalk.iapplabz.co.in/api/v1/';
let apiBaseUrl = 'https://be.outrightalk.com/api/v1/';

// let apiBaseUrl = 'http://localhost:8000/api/v1/';
// let socketUrl = 'https://outrighttalk.glohtesting.com:3262';
// let socketUrl = 'https://outrightalk.iapplabz.co.in:3262';
let socketUrl = 'https://node.outrightalk.com';
// let socketUrl = 'https://localhost:3264';
let fileUrl = 'https://be.outrightalk.com/pdf/'
// let fileUrl = 'http://localhost:8000/pdf/';

let appStage = process.env.REACT_APP_STAGE

if (appStage == 'dev') {
  // apiBaseUrl = 'https://outrighttalk-backend.glohtesting.com/api/v1/';
  // apiBaseUrl = 'https://outrightalk.iapplabz.co.in/api/v1/'
  apiBaseUrl = 'https://be.outrightalk.com/api/v1/'
    //  apiBaseUrl = 'http://127.0.0.1:8000/api/v1/';
  // socketUrl = 'https://outrighttalk.glohtesting.com:3262';
  // socketUrl = 'https://outrightalk.iapplabz.co.in:3262';
  //  socketUrl = 'https://localhost:3264';
  //  fileUrl = 'http://localhost:8000/pdf/';
  socketUrl = 'https://node.outrightalk.com';
   fileUrl = 'https://be.outrightalk.com/pdf/';
} else if (appStage == 'stage') {
  // apiBaseUrl = 'https://outrighttalk-backend.glohtesting.com/api/v1/';
  // apiBaseUrl = 'https://outrightalk.iapplabz.co.in/api/v1/'
  apiBaseUrl = 'https://be.outrightalk.com/api/v1/'
  // socketUrl = 'https://outrighttalk.glohtesting.com:3262';
  // socketUrl = 'https://outrightalk.iapplabz.co.in:3262';
  socketUrl = 'https://node.outrightalk.com';
   fileUrl = 'https://be.outrightalk.com/pdf/';

} else if (appStage == 'prod') {
  // apiBaseUrl = 'https://outrighttalk-backend.glohtesting.com/api/v1/';
  // apiBaseUrl = 'https://outrightalk.iapplabz.co.in/api/v1/'
  apiBaseUrl = 'https://be.outrightalk.com/api/v1/'
  // socketUrl = 'https://outrighttalk.glohtesting.com:3262';
  // socketUrl = 'https://outrightalk.iapplabz.co.in:3262';
  socketUrl = 'https://node.outrightalk.com';
   fileUrl = 'https://be.outrightalk.com/pdf/';

}

export const API_BASE_URL = apiBaseUrl
export const SOCKETURL = socketUrl
export const FILE_URL = fileUrl