import React, { useState, useEffect, useContext } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MetisMenu from "@metismenu/react";
import { contextMenu } from "react-contexify";
import { NavLink, Link, useHistory } from "react-router-dom";
import SocketLoader from "src/socket/socketLoader";
import LeftBarPersonContextMenu from "../clickContextMenu/leftbarPersonContextMenu";
import StatusToggleMenu from "../clickContextMenu/profileStatusContextMenu";
import {
  useAppUserDetailsSelector,
  useAppLoggedinFromOtherLocation,
} from "src/_common/hooks/selectors/userSelector";
import {
  useAppLeftMenuItemListSelector,
  useAppInstanceInvitedUsers,
  useAppRoomDetailsSelector, useAppAmountSelector,
} from "src/_common/hooks/selectors/groupCategorySelector";
import { useGroupCategoryApi } from "src/_common/hooks/actions/groupCategory/appGroupCategoryApiHook";
import { useAppNotebookList } from "src/_common/hooks/selectors/notebookSelector";
import {
  URLS,
  getNameInitials,
  getBooleanStatus,
  getStatusColor,
  getAvailabiltyStatusText,
  MENU_OPERATIONS,
  getValueFromArrayOfObject,
  CRYPTO_SECRET_KEY,
  PM_TYPE,
  CUSTOM_MESSAGE,
  STORAGE,
  APP_VERSION,
  trimTo,
  getSubscriptionColor,
} from "src/_config";
import { UpdateShowHideProfilePicture } from "src/_common/interfaces/ApiReqRes";
import { useUserApi } from "src/_common/hooks/actions/user/appUserApiHook";
import { usePmWindowApi } from "src/_common/hooks/actions/pmWindow/appPmWindowApiHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import ViewProfileModal from "src/components/commonModals/viewProfileModal/viewProfileModal";
import RoomInvitationNotificationModal from "../commonModals/roomInvitationNotificationModal/roomInvitationNotificationModal";
import StickerBuyModal from "src/components/commonModals/stickerBuyModal/stickerBuyModal";
import clsx from "clsx";
import CustomizedNicknameModal from "../groupsCategory/roomsDetail/modal/customizedNicknameModal";
import { useAppPmWindowAction } from "src/_common/hooks/actions/pmWindow/appPmWindowActionHook";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { useAppGroupCategoryAction } from "src/_common/hooks/actions/groupCategory/appGroupCategoryActionHook";
import { usePmDigSoundSelector } from "src/_common/hooks/selectors/pmWindowSelector";
// import { AntmediaContext } from 'src';
import { useDispatch } from 'react-redux';
import { updateAmount } from 'src/_common/hooks/actions/common/appAmountHook';

import SideBarBanner from "./sidebarBanner";
import { RootStateOrAny, useSelector } from "react-redux";
const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

function Sidebar() {
  const history = useHistory();
  const [alert, setAlert] = useState<any>(null);
  const userSelector = useAppUserDetailsSelector();
  const loggedInFromOtherLocation = useAppLoggedinFromOtherLocation();
  const userAction = useAppUserAction();
  const groupCategoryAction = useAppGroupCategoryAction();
  const notebookListSelector: any = useAppNotebookList();
  const leftMenuItemDetails = useAppLeftMenuItemListSelector();
  const instanceInvitedUsersSelector = useAppInstanceInvitedUsers();
  const groupCategoryApi = useGroupCategoryApi();
  const pmWindowApi = usePmWindowApi();
  const pmWindowAction = useAppPmWindowAction();
  const userApi = useUserApi();
  const toast = useToaster();
  const [membersData, setMembersData] = useState<any>();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [loginUsersData, setLoginUsersData] = useState<any>();
  const [isContactSelected, setIsContactSelected] = useState<any>();
  const [isSelected, setIsSelected] = useState<any>();
  const [isLoginUser, setIsLoginUser] = useState<boolean>(false);
  const [noteBookNewCount, setNotebookNewCount] = useState<number>(0);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [showRoomInvitationModal, setShowRoomInvitationModal] = useState<boolean>(false);
  const [showStickerBuyModal, setShowStickerBuyModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any[]>([]);
  const [onlineUserCount, setOnlineUserCount] = useState<number>(1);

  const [showCustomizedNicknameModal, setCustomizedNicknameModal] = useState<boolean>(false);

  const pmWindowDigSoundSelector = usePmDigSoundSelector();
  const roomDetailsSelector = useAppRoomDetailsSelector();
  const amountSelector = useAppAmountSelector();
  // const antmedia = useContext<any>(AntmediaContext);

  const [ActiveRoomData, setActiveRoomData] = useState<any>();
  const dispatch = useDispatch();
  console.log("membersData", membersData)

  const customizedNicknameModalClose = () => {
    if (showCustomizedNicknameModal) {
      setMembersData(null);
      setCustomizedNicknameModal(false);
    }
  };

  const handleViewProfile = (id: number) => {
    setSelectedUserId(id);
    setShowProfileModal(true);
  };

  const onViewProfileModalClose = () => {
    setShowProfileModal(false);
  };

  const addUserAsFavouriteFromContact = (id: number) => {
    const params = {
      contact_user_id: id,
    };
    groupCategoryApi.callAddUserAsFavouriteFromContact(
      params,
      (message: string, resp: any) => {
        if (resp) {
          console.log("Resp ---------------->", resp);
        }
      },
      (message: string) => {
        console.error("Error at add as favourite contact");
      }
    );
  };

  const handleEnterRoom = (data: any) => {
    if (data.contact_user.visible_status == 3) {
      return
    }
    else {
      groupCategoryAction.emptyRoomDetails();
      groupCategoryAction.fromRouteHandler(data.first_room.room_details.id);
      const groupId = cryptr.encrypt(data.first_room.room_details.group_id);
      console.log('------------------------------groupId', groupId);
      const roomId = cryptr.encrypt(data.first_room.room_details.id);
      // history.replace("");
      history.push(`${groupId}/${roomId}/room-details`);
    }
  };

  const handleAddToBlockList = (id: number) => {
    if (id === userSelector?.id) {
      toast.error(CUSTOM_MESSAGE.OTHERS.BLOCK_HIMSELF);
    } else {
      const params = {
        block_user_id: id,
      };
      groupCategoryApi.callAddToBlockList(
        params,
        (message: string, resp: any) => {
          if (resp) {
          }
        },
        (message: string) => {
          console.error("Error at add to block list");
        }
      );
    }
  };


  useEffect(() => {
    if (
      leftMenuItemDetails &&
      leftMenuItemDetails.online_users &&
      leftMenuItemDetails.online_users.length && leftMenuItemDetails.show_room_i_am_in_options && leftMenuItemDetails.show_room_i_am_in_options.show_room_i_am_in_options
    ) {
      // console.log('leftMenuItemDetails----------------------',leftMenuItemDetails);
      dispatch(updateAmount(leftMenuItemDetails.show_room_i_am_in_options.show_room_i_am_in_options));
      leftMenuItemDetails.online_users.forEach((element: any) => {
        setOnlineUserCount(1);
        setTimeout(() => {
          if (element.is_bloked_by_them == null) {
            setOnlineUserCount((prev) => prev + 1);
          }
        }, 500);
      });
    } else {

      setOnlineUserCount(1);
    }

  }, [leftMenuItemDetails, dispatch]);

  //Function for click on send pm
  const handleSendPm = (id: number) => {
    const params = {
      user_id: id,
    };

    pmWindowApi.callSendPms(
      params,
      (message: string, resp: any) => {
        if (resp) {
          const userId = cryptr.encrypt(resp.id);
          // history.replace("");
          history.push(`/pm/${userId}`);
        } else {
          toast.error(message);
        }
      },
      (message: string, resp: any) => {
        toast.error(message);
      }
    );
  };

  const handleRedirectToPm = (id: number) => {
    // if (antmedia.publishStreamId) antmedia.handleLeaveFromRoom();

    const params = {
      pm_id: id,
    };
    pmWindowApi.callReadPm(
      params,
      (message: string, resp: any) => {
        if (resp) {
          const pmId = cryptr.encrypt(id);
          pmWindowAction.fromRouteHandler(id);
          // history.replace("");
          history.push(`/pm/${pmId}`);
        } else {
          toast.error(message);
        }
      },
      (message: string, resp: any) => {
        toast.error(message);
      }
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const showAlert = (e: React.MouseEvent, userId: any) => {
    e && e.preventDefault();
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnBsStyle="danger"
        confirmBtnBsStyle="success"
        allowEscape={false}
        closeOnClickOutside={false}
        title="Remove from favourite list"
        onConfirm={() => removeFavourite({ contact_user_id: userId })}
        onCancel={hideAlert}
        focusCancelBtn={true}
      >
        Are you sure to remove from favourite contact list?
      </SweetAlert>
    );
  };

  //for stricker
  const openStickerBuyModal = (id: number) => {
    // e.preventDefault()
    // setByStickerModalType('ownStickerBuy')
    setSelectedUser([id]);
    setShowStickerBuyModal(true);
  };

  const handleOnCloseSticker = () => {
    setShowStickerBuyModal(false);
    setSelectedUser([]);
    // setSelectedContactList([])
    // setByStickerModalType('')
    // getStickerCategory()
  };

  // right click context menu

  const onContextMenuClick = (e: any, profile: any) => {
    e.preventDefault();
    setMembersData(profile);
    setLoginUsersData(userSelector);
    setIsLoginUser(false);

    // if (!profile.is_block) {
    //   if (!(profile.contact_user.id === userSelector?.id)) {
    contextMenu.show({
      id: "menu_id",
      event: e,
      props: {
        profile: profile,
      },
    });
    //   }
    // }
  };

  const onSelfContextMenuClick = (e: any, profile: any) => {
    e.preventDefault();
    setMembersData(profile);
    setLoginUsersData(profile);
    setIsLoginUser(true);
    contextMenu.show({
      id: "menu_id",
      event: e,
      props: {
        profile: profile,
      },
    });
  };

  const contextMenuOperationParams = (data: {
    id: number;
    userName: string;
    customize_nickname: any;
    operation: string;
  }) => {
    switch (data.operation) {
      case MENU_OPERATIONS.SEND_PM:
        handleSendPm(data.id);
        break;
      case MENU_OPERATIONS.VIEW_PROFILE:
        handleViewProfile(data.id);
        break;
      case MENU_OPERATIONS.ADD_TO_FAVOURITE_CONTACT:
        addUserAsFavouriteFromContact(data.id);
        break;
      case MENU_OPERATIONS.SEND_VIRTUAL_GIFT:
        openStickerBuyModal(data.id);
        break;

      case MENU_OPERATIONS.SEND_GIFT_SUBSCRIPTION:
        openStickerBuyModal(data.id);
        break;

      case MENU_OPERATIONS.CUSTOMIZED_NICKNAME:
        setCustomizedNicknameModal(true);
        setMembersData({
          id: data.id,
          userName: data.userName,
          customize_nickname: data.customize_nickname,
        });
        break;
      case MENU_OPERATIONS.ADD_TO_BLOCK_LIST:
        handleAddToBlockList(data.id);
        break;
      case MENU_OPERATIONS.REMOVE_FROM_CONTACT_LIST:
        handleRemoveFromContact(data.id);
        break;
      default:
        break;
    }
  };

  const handleRemoveFromContact = (id: any) => {
    console.log("Event --------->", id, leftMenuItemDetails);

    let selectedUsers: any;
    let user: any;
    user = leftMenuItemDetails.favourite_contact.filter(
      (user: any) => user.contact_user_id === id
    );

    if (!(user.length)) {
      user = leftMenuItemDetails.offline_users.filter(
        (user: any) => user.contact_user_id === id
      );
    }

    if (!(user.length)) {
      user = leftMenuItemDetails.online_users.filter(
        (user: any) => user.contact_user_id === id
      );
    }

    user = user[0];

    if (user) {
      selectedUsers =
        user.customize_nickname && user.customize_nickname.nickname
          ? user.customize_nickname.nickname
          : user && user.contact_user
            ? user.contact_user.username
            : "--";
    }

    console.log(user, selectedUsers);

    if (user && selectedUsers) {
      setAlert(
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          cancelBtnBsStyle="danger"
          confirmBtnBsStyle="success"
          allowEscape={false}
          closeOnClickOutside={false}
          title="Remove User"
          onConfirm={() =>
            removeFromContactList(user, selectedUsers.toString())
          }
          onCancel={hideAlert}
          focusCancelBtn={true}
        >
          {`Are you sure you want to remove (${selectedUsers.toString()}) from your Contact List?`}
        </SweetAlert>
      );
    }
  };

  const removeFromContactList = (user: any, username: string | null) => {
    hideAlert();

    const params = {
      contact_user_id: user.contact_user_id,
      user_id: user.user_id,
    };

    groupCategoryApi.callRemoveFromContactList(
      params,
      (message: string, resp: any) => {
        if (resp) {
          toast.success(
            `${username} has been successfully removed from your Contact List`
          );
          // handleChangeAccount({
          //   label: selctedAccount,
          //   value: selctedAccount
          // })
        }
      },
      (message: string) => {
        console.error("Error at remove from contact list");
      }
    );
  };

  const statusToggle = (e: any, status: any) => {
    contextMenu.show({
      id: "status_toggle_id",
      event: e,
      props: {
        status: status,
      },
    });
  };

  const toggleShowHideProfilePicture = (e: any) => {
    const params: UpdateShowHideProfilePicture = {
      avatar_visible: e == true ? 1 : 0,
    };

    userApi.callUpdateProfilePictureShowHide(
      params,
      (message: string, resp: any) => {
        if (resp) {
          toast.success(message);
        } else {
          toast.error(message);
        }
      },
      (message: string, resp: any) => {
        toast.error(message);
      }
    );
  };

  const getLeftMenuItemsList = () => {
    groupCategoryApi.callLeftMenuItemsList(
      (message: string, resp: any) => {
        if (resp && resp.data) {
          const newAmount = resp.data.show_room_i_am_in_options.show_room_i_am_in_options;
          dispatch(updateAmount(newAmount));
        }
      },
      (message: string) => {
        // toast.error(message)
      }
    );
  };

  const removeFavourite = (params: any) => {
    // e.preventDefault();
    // const params = {
    //   contact_user_id: id
    // }
    groupCategoryApi.callRemoveFavouriteContact(
      params,
      (message: string, resp: any) => {
        if (resp) {
          hideAlert();
          getLeftMenuItemsList();
        }
      },
      (message: string) => {
        hideAlert();
      }
    );
  };

  useEffect(() => {
    getLeftMenuItemsList();
  }, []);

  //Used for notebook Un read message count
  useEffect(() => {
    if (notebookListSelector && notebookListSelector.length) {
      let count = notebookListSelector.filter(
        (note: any) => note.is_viewed === 0
      );
      if (count && count.length) {
        setNotebookNewCount(count.length);
      }
    }
  }, [notebookListSelector]);

  //Instance Invited Users Selector Update effect
  // useEffect(() => {
  //   if (instanceInvitedUsersSelector && instanceInvitedUsersSelector.length) {
  //     instanceInvitedUsersSelector.map((x: any) => {
  //       if (x.id == (userSelector && userSelector.id)) {
  //         toast.error(x.msg)
  //       }
  //     })
  //   }
  // }, [instanceInvitedUsersSelector])

  const LogOut = () => {
    localStorage.removeItem(STORAGE);
    localStorage.setItem("isAdult", "0");
    groupCategoryAction.emptyRoomListCategoryWise();
    userAction.loggedInFromOtherLocation(null);
    userAction.logout();
    history.push(URLS.LOGIN);
  };

  useEffect(() => {
    if (loggedInFromOtherLocation) {
      if (userSelector?.id === loggedInFromOtherLocation?.user_id) {
        setAlert(
          <SweetAlert
            warning
            // showCancel
            confirmBtnText="Ok"
            // cancelBtnText="No"
            // cancelBtnBsStyle="danger"
            confirmBtnBsStyle="success"
            allowEscape={false}
            closeOnClickOutside={false}
            title="Alert"
            onConfirm={() => LogOut()}
          // onCancel={hideAlert}
          // focusCancelBtn={false}
          >
            {loggedInFromOtherLocation.message}
          </SweetAlert>
        );
      }
    }
  }, [loggedInFromOtherLocation, userSelector]);

  const getPrivacySettingsRoomIamInOptions = (settings: any[]) => {
    for (let i = 0; i < settings.length; i++) {
      let val = settings[i].val ? settings[i].val : "";
      return val;
    }
  };

  //Dig Sound notification

  const playSound = () => {
    const audio = new Audio(pmWindowDigSoundSelector.sound.sound.thumb);
    audio.play();
  };

  const getActiveRoom = () => {
    groupCategoryApi.callGetMyActiveRooms((message: string, resp: any) => {
      const arr = resp.active_rooms;
      const last = arr[arr.length - 1];
      setActiveRoomData(last);
    }, (message: string) => {

    })
  }

  useEffect(() => {
    if (
      pmWindowDigSoundSelector &&
      pmWindowDigSoundSelector.sound &&
      pmWindowDigSoundSelector.sound.sound &&
      pmWindowDigSoundSelector.sound.sound.original
    ) {
      if (
        pmWindowDigSoundSelector &&
        pmWindowDigSoundSelector.users &&
        pmWindowDigSoundSelector.users.length
      ) {
        toast.success(pmWindowDigSoundSelector.users[0].message);
      }
      setTimeout(() => {
        var soundButton = document.getElementById("playSoundBtn");
        if (soundButton) {
          soundButton.click();
        }
      }, 150);

      setTimeout(() => {
        pmWindowAction.digSoundSocketData(null);
      }, 300);
    }

    getActiveRoom();

  }, [pmWindowDigSoundSelector]);



  const userId = userSelector && userSelector.id ? userSelector.id : 0;
  // const amount = useSelector((state: RootStateOrAny) => state.amount);
  const amount: any = amountSelector.amount;

  return (
    <React.Fragment>
      <div>
        <div id="sidebar-menu" className="left-sidebar">
          {/* <div className="sidebar_height"></div> */}
          {alert}
          <button
            type="button"
            style={{ display: "none" }}
            id="playSoundBtn"
            onClick={playSound}
          ></button>
          <div className="sidebar-profile-panel d-flex align-items-center mb-4">
            <div className="sidebar-profile-pic">
              <div className="sidebar-profile-pic-inner">
                {userSelector &&
                  userSelector.avatar &&
                  userSelector.avatar.thumb ? (
                  <img
                    src={userSelector.avatar.thumb}
                    height={"62px"}
                    width={"62px"}
                  />
                ) : (
                  <span>{getNameInitials(userSelector?.username)}</span>
                )}
              </div>
              <span
                className={
                  userSelector && userSelector.visible_status
                    ? "user-status st-" + userSelector.visible_status
                    : "user-status st-1"
                }
                onClick={(e) => statusToggle(e, "status")}
              />
            </div>
            <div className="sidebar-profile-info">
              {/* Change Username Color when user enterd into the room & color will be based on room subscription if user is owner tof that room */}

              <h4
                // style={{
                //   color: getSubscriptionColor(
                //     roomDetailsSelector && roomDetailsSelector.room
                //       ? roomDetailsSelector.members &&
                //         roomDetailsSelector.members.length &&
                //         roomDetailsSelector.members.filter(
                //           (x: any) => x.user_id === userSelector?.id
                //         )[0] &&
                //         roomDetailsSelector.members.filter(
                //           (x: any) => x.user_id === userSelector?.id
                //         )[0].is_admin === 3
                //         ? roomDetailsSelector.room
                //         : null
                //       : userSelector && userSelector.is_subscribed
                //       ? {
                //           ...userSelector,
                //           subscription_info: userSelector.is_subscribed,
                //         }
                //       : null
                //   ),
                // }}
                style={{
                  color: getSubscriptionColor(userSelector),
                }}
              >
                {userSelector && userSelector.username
                  ? userSelector.username
                  : ".."}
                {userSelector &&
                  userSelector.badge_data &&
                  userSelector.badge_data.current_badge &&
                  new Date(userSelector.badge_data.expiry_date.replaceAll("-", "/")).getTime() >
                  new Date().getTime() ? (
                  <img
                    src={
                      userSelector?.badge_data?.current_badge?.icon?.original
                    }
                    height={25}
                    width={25}
                    className="m-2"
                    alt=""
                  />
                ) : (
                  ""
                )}
              </h4>
              <h5>
                {getAvailabiltyStatusText(
                  userSelector && userSelector.visible_status
                )}
              </h5>
              <span
                className="about-text"
                title={
                  userSelector && userSelector.about ? userSelector.about : ""
                }
              >
                {userSelector && userSelector.about
                  ? trimTo(userSelector.about, 30, true)
                  : null}
              </span>
            </div>
          </div>

          <div className="sidebar-profile-tools-panel">
            {/* <a href="#" className="nearby-btn waves-effect">
            <i className="find-nearby-icon mr-2" />
            Find nearby users
          </a> */}
            <NavLink
              to={URLS.USER.NEAR_BY_USER}
              className="nearby-btn waves-effect"
            >
              <i className="find-nearby-icon mr-2" />
              Find nearby users
            </NavLink>

            <div className="d-flex justify-content-between align-items-baseline mt-4 mb-2 user-btns-panel">
              <NavLink
                to={URLS.USER.GROUPS_AND_CATEGORY}
                activeClassName="active"
              >
                <i className="group-icon mb-1" />
                Groups <br />
                &amp; Category
              </NavLink>

              <NavLink
                to={URLS.USER.MANAGE_VOICE_MAIL}
                activeClassName="active"
              >
                <i className="voicemails-icon mb-1">
                  <span className="noticount">
                    {leftMenuItemDetails &&
                      leftMenuItemDetails.voice_unread_message_cnt
                      ? leftMenuItemDetails.voice_unread_message_cnt
                      : 0}
                  </span>
                </i>
                Voicemails
              </NavLink>

              <NavLink
                to={URLS.USER.MANAGE_VIDEO_MESSAGE}
                activeClassName="active"
              >
                <i className="video-msg-icon mb-1">
                  <span className="noticount">
                    {leftMenuItemDetails &&
                      leftMenuItemDetails.video_unread_message_cnt
                      ? leftMenuItemDetails.video_unread_message_cnt
                      : 0}
                  </span>
                </i>
                Video Messages
              </NavLink>

              <NavLink to={URLS.USER.NOTEBOOK} activeClassName="active">
                <i className="notebook-icon mb-1">
                  {/* {
                  noteBookNewCount && noteBookNewCount > 0 ?
                    <span className="noticount">
                      {
                        noteBookNewCount
                      }
                    </span> : null
                } */}

                  <span className="noticount">
                    {leftMenuItemDetails &&
                      leftMenuItemDetails.notebook_unread_count
                      ? leftMenuItemDetails.notebook_unread_count
                      : 0}
                  </span>
                </i>
                Notebook
              </NavLink>
            </div>
          </div>

          {/* Left Menu Start */}

          <MetisMenu>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="sidebar-icon-1" />
                <span key="t-layouts">
                  Recent PMs{" "}
                  {leftMenuItemDetails &&
                    leftMenuItemDetails.recent_pms &&
                    leftMenuItemDetails.recent_pms.length
                    ? "(" + leftMenuItemDetails.recent_pms.length + ")"
                    : ""}
                </span>
                {/* {leftMenuItemDetails && leftMenuItemDetails.recent_pms && leftMenuItemDetails.recent_pms.length ? '(' + leftMenuItemDetails.recent_pms.length + ')' : ''} */}
              </Link>
              {
                console.log("leftMenuItemDetails.recent_pms", leftMenuItemDetails?.recent_pms)
              }
              <ul className="sub-menu" aria-expanded="true">
                {leftMenuItemDetails &&
                  leftMenuItemDetails.recent_pms &&
                  leftMenuItemDetails.recent_pms.length ? (
                  leftMenuItemDetails.recent_pms.map(
                    (items: any, index: number) => {
                      // console.log(items.for_user_info);

                      return (
                        <li key={index}>
                          <Link to="#">
                            <span className="sub-menu-avatar">
                              {items &&
                                items.pm_info &&
                                items.pm_info.pm_type === PM_TYPE.SINGLE ? (
                                <img
                                  className="recent-pms-avatar"
                                  src="/img/sidebar-icon-3.png"
                                  alt="single"
                                />
                              ) : (
                                <img
                                  className="recent-pms-avatar"
                                  src="/img/group-icon.png"
                                  alt="group"
                                />
                              )}
                            </span>
                            <div
                              className="sub-menu-info"
                              // style={{
                              //   color: getSubscriptionColor(
                              //     items &&
                              //       items.for_user_info &&
                              //       items.for_user_info.is_subscribed
                              //       ?
                              //       items.for_user_info
                              //       : null
                              //   ),
                              // }}

                              // onClick={() => handleRedirectToPm(items.pm_id)}
                              onClick={() => handleSendPm(items.for_user_id == userSelector?.id ? items.user_id : items.for_user_id)}
                            >
                              {items.user_id == userId
                                ? items.for_user_info.customize_nickname &&
                                  items.for_user_info.customize_nickname
                                    .nickname
                                  ? items.for_user_info.customize_nickname
                                    .nickname
                                  : items.for_user_info.username
                                : items.user_info.customize_nickname &&
                                  items.user_info.customize_nickname.nickname
                                  ? items.user_info.customize_nickname.nickname
                                  : items.user_info.username}
                              {/* <span>
                              <i className="oline-tag"
                                style={{ backgroundColor: getStatusColor(items.contact_user.visible_status) }} />{getAvailabiltyStatusText(items.contact_user.visible_status)}
                            </span> */}
                            </div>
                          </Link>
                        </li>
                      );
                    }
                  )
                ) : (
                  <li>
                    <p>No pms found</p>
                  </li>
                )}
              </ul>
            </li>

            <li>
              <a href="#" className="has-arrow waves-effect">
                <i className="sidebar-icon-2" />
                <span key="t-layouts">
                  Favorites Contacts{" "}
                  {leftMenuItemDetails &&
                    leftMenuItemDetails.favourite_contact &&
                    leftMenuItemDetails.favourite_contact.length
                    ? "(" + leftMenuItemDetails.favourite_contact.length + ")"
                    : ""}
                </span>
              </a>
              <ul className="sub-menu" aria-expanded="true">
                {leftMenuItemDetails &&
                  leftMenuItemDetails.favourite_contact &&
                  leftMenuItemDetails.favourite_contact.length ? (
                  leftMenuItemDetails.favourite_contact.map(
                    (items: any, index: number) => (
                      <li key={index}>
                        <Link
                          to="#"
                          className={clsx({
                            "disable-link": items.is_block,
                          })}
                          onContextMenu={(e) => onContextMenuClick(e, items)}
                          onDoubleClick={() => {
                            if (items.contact_user.visible_status == 3) {
                              return
                            }
                            else {
                              handleSendPm(items.contact_user.id)
                            }
                          }
                          }
                        >
                          <div className="test">
                            <span className="sub-menu-avatar">
                              {items &&
                                items.contact_user &&
                                items.contact_user.avatar &&
                                getBooleanStatus(
                                  items.contact_user.avatar &&
                                    items.contact_user.avatar.visible_avatar
                                    ? items.contact_user.avatar.visible_avatar
                                    : 0
                                ) &&
                                items.contact_user.avatar.thumb ? (
                                <img
                                  src={items.contact_user.avatar.thumb}
                                  alt={items.contact_user.username}
                                />
                              ) : (
                                <span className="text-avatar">
                                  {getNameInitials(items.contact_user.username)}
                                </span>
                              )}
                            </span>
                            <div
                              className="sub-menu-info"
                              style={{
                                color: getSubscriptionColor(
                                  items &&
                                    items.contact_user &&
                                    items.contact_user.is_subscribed
                                    ? {
                                      ...items,
                                      subscription_info:
                                        items.contact_user.is_subscribed,
                                    }
                                    : null
                                ),
                              }}
                            >
                              {items.customize_nickname &&
                                items.customize_nickname.nickname
                                ? items.customize_nickname.nickname
                                : items.contact_user.username}
                              <span>
                                <i
                                  className="oline-tag"
                                  style={{
                                    backgroundColor: getStatusColor(
                                      items.contact_user.visible_status
                                    ),
                                  }}
                                />
                                {getAvailabiltyStatusText(
                                  items.contact_user.visible_status
                                )}
                              </span>

                            </div>
                          </div>
                          <div>
                            <span>
                              {items.privacy_setting &&
                                items.privacy_setting.length ? (
                                getPrivacySettingsRoomIamInOptions(
                                  items.privacy_setting.filter(
                                    (x: any) =>
                                      x.key === "show_room_i_am_in" &&
                                      x.field_type_details === "checkbox"
                                  )
                                ) == "0" ? (
                                  <p
                                    className="left-menu-tooltip"
                                    title={items.contact_user.about}
                                  >
                                    {trimTo(items.contact_user.about, 20, true)}
                                  </p>
                                ) : (getPrivacySettingsRoomIamInOptions(
                                  items.privacy_setting.filter(
                                    (x: any) =>
                                      x.key === "show_room_i_am_in_options" &&
                                      x.field_type_details === "radio"
                                  )
                                ) == "1" &&
                                  items.is_in_contact) ||
                                  getPrivacySettingsRoomIamInOptions(
                                    items.privacy_setting.filter(
                                      (x: any) =>
                                        x.key === "show_room_i_am_in_options" &&
                                        x.field_type_details === "radio"
                                    )
                                  ) == "2" ? (
                                  <p
                                    className="left-menu-tooltip"
                                    title={
                                      items.first_room &&
                                        items.first_room.room_details
                                        ? items.first_room.room_details
                                          .room_name
                                        : ""
                                    }
                                  >
                                    {trimTo(
                                      items.first_room &&
                                        items.first_room.room_details
                                        ? items.first_room.room_details
                                          .room_name
                                        : "",
                                      20,
                                      true
                                    )}
                                  </p>
                                ) : null
                              ) : null}
                            </span>
                          </div>
                        </Link>

                        <a
                          href="#"
                          onClick={(e) => showAlert(e, items.contact_user_id)}
                          className="submenu-close-btn"
                        />
                      </li>
                    )
                  )
                ) : (
                  <li>No Contact found</li>
                )}
              </ul>
            </li>
            <li>
              <a href="#" className="has-arrow waves-effect">
                <i className="sidebar-icon-3" />
                <span key="t-layouts">
                  Online{" "}
                  {leftMenuItemDetails &&
                    leftMenuItemDetails.online_users &&
                    leftMenuItemDetails.online_users.length
                    ? // "(" + onlineUserCount + ")"
                    "(" +
                    `${leftMenuItemDetails.online_users.length &&
                    leftMenuItemDetails.online_users.filter(
                      (u: any) => u.is_bloked_by_them == null
                    ).length + 1
                    }` +
                    ")"
                    : "(1)"}
                </span>
              </a>
              <ul className="sub-menu" aria-expanded="true">
                {/* Current user Static Data Added */}
                <li>
                  {/* onContextMenu={(e) => onContextMenuClick(e, userSelector)} */}
                  <Link
                    to="#"
                    onContextMenu={(e) =>
                      onSelfContextMenuClick(e, userSelector)
                    }
                    onDoubleClick={() =>
                      handleSendPm(userSelector ? userSelector.id : 0)
                    }
                  >
                    <span className="sub-menu-avatar">
                      {userSelector &&
                        userSelector.avatar &&
                        userSelector.avatar.thumb ? (
                        <img
                          src={userSelector.avatar.thumb}
                          alt={userSelector.username}
                        />
                      ) : (
                        <span className="text-avatar">
                          {getNameInitials(userSelector?.username)}
                        </span>
                      )}
                    </span>
                    <div
                      className="sub-menu-info"
                      style={{
                        color: getSubscriptionColor(
                          userSelector && userSelector.is_subscribed
                            ? {
                              ...userSelector,
                              subscription_info: userSelector.is_subscribed,
                            }
                            : null
                        ),
                      }}
                    >
                      {userSelector?.username}
                      {userSelector &&
                        userSelector.badge_data &&
                        userSelector.badge_data.current_badge &&
                        new Date(userSelector.badge_data.expiry_date.replaceAll("-", "/")).getTime() >
                        new Date().getTime() ? (
                        <img
                          src={
                            userSelector?.badge_data?.current_badge?.icon
                              ?.original
                          }
                          height={20}
                          width={20}
                          className="m-2"
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                      <span>
                        <i
                          className="oline-tag"
                          style={{
                            backgroundColor: getStatusColor(
                              userSelector?.visible_status
                            ),
                          }}
                        />{" "}
                        {getAvailabiltyStatusText(userSelector?.visible_status)}
                      </span>
                      <span>
                        <p
                          className="left-menu-tooltip"
                          title=""
                        >{amount == 3 ? '' :
                          ActiveRoomData && ActiveRoomData.room_name
                          }</p>
                      </span>
                    </div>
                  </Link>
                </li>

                {
                  leftMenuItemDetails &&
                    leftMenuItemDetails.online_users &&
                    leftMenuItemDetails.online_users.length
                    ? leftMenuItemDetails.online_users.map(
                      (items: any, index: number) => {
                        if (items.is_bloked_by_them == null) {
                          return (
                            <li key={index}>
                              <Link
                                to="#"
                                className={clsx({
                                  "disable-link":
                                    items.is_block || items.is_bloked_by_them,
                                })}
                                onContextMenu={(e) =>
                                  onContextMenuClick(e, items)
                                }
                                onDoubleClick={() => {
                                  if (items.contact_user.visible_status == 3) {
                                    return
                                  }
                                  else {
                                    handleSendPm(items.contact_user.id)
                                  }

                                }
                                }
                              >
                                <span className="sub-menu-avatar">
                                  {items &&
                                    items.contact_user &&
                                    items.contact_user.avatar &&
                                    getBooleanStatus(
                                      items.contact_user.avatar &&
                                        items.contact_user.avatar.visible_avatar
                                        ? items.contact_user.avatar
                                          .visible_avatar
                                        : 0
                                    ) &&
                                    items.contact_user.avatar.thumb ? (
                                    <img
                                      src={items.contact_user.avatar.thumb}
                                      alt={items.contact_user.username}
                                    />
                                  ) : (
                                    <span className="text-avatar">
                                      {getNameInitials(
                                        items.contact_user.username
                                      )}
                                    </span>
                                  )}
                                </span>
                                <div
                                  className="sub-menu-info"
                                  style={{
                                    color: getSubscriptionColor(
                                      items &&
                                        items.contact_user &&
                                        items.contact_user.is_subscribed
                                        ? {
                                          ...items,
                                          subscription_info:
                                            items.contact_user
                                              .is_subscribed,
                                        }
                                        : null
                                    ),
                                  }}
                                >
                                  {items.customize_nickname &&
                                    items.customize_nickname.nickname
                                    ? items.customize_nickname.nickname
                                    : items.contact_user.username}
                                  {items &&
                                    items.badge_data &&
                                    items.badge_data.current_badge &&
                                    new Date(
                                      items.badge_data.expiry_date.replaceAll("-", "/")
                                    ).getTime() > new Date().getTime() ? (
                                    <img
                                      src={
                                        items?.badge_data?.current_badge?.icon
                                          ?.original
                                      }
                                      height={20}
                                      width={20}
                                      className="m-2"
                                      alt=""
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <span>
                                    <i
                                      className="oline-tag"
                                      style={{
                                        backgroundColor: getStatusColor(
                                          items.contact_user.visible_status
                                        ),
                                      }}
                                    />{" "}
                                    {getAvailabiltyStatusText(
                                      items.contact_user.visible_status
                                    )}
                                  </span>
                                  <span>
                                    {items.privacy_setting &&
                                      items.privacy_setting.length ? (
                                      getPrivacySettingsRoomIamInOptions(
                                        items.privacy_setting.filter(
                                          (x: any) =>
                                            x.key === "show_room_i_am_in" &&
                                            x.field_type_details ===
                                            "checkbox"
                                        )
                                      ) == "0" ? (
                                        <p
                                          className="left-menu-tooltip"
                                          title={items.contact_user.about}
                                        >

                                          {trimTo(
                                            items.contact_user.about,
                                            20,
                                            true
                                          )}
                                        </p>
                                      ) : (getPrivacySettingsRoomIamInOptions(
                                        items.privacy_setting.filter(
                                          (x: any) =>
                                            x.key ===
                                            "show_room_i_am_in_options" &&
                                            x.field_type_details === "radio"
                                        )
                                      ) == "1" &&
                                        items.is_in_contact) ||
                                        getPrivacySettingsRoomIamInOptions(
                                          items.privacy_setting.filter(
                                            (x: any) =>
                                              x.key ===
                                              "show_room_i_am_in_options" &&
                                              x.field_type_details === "radio"
                                          )
                                        ) == "2" ? (
                                        <p
                                          className="left-menu-tooltip"
                                          title={
                                            items.first_room &&
                                              items.first_room.room_details
                                              ? items.first_room.room_details
                                                .room_name
                                              : ""
                                          }
                                          onDoubleClick={() => handleEnterRoom(items)}
                                          style={{ color: "#556EE6" }}
                                        >
                                          {amount == 3 ? '' : amount == 2 ?
                                            <>

                                              {trimTo(
                                                items.first_room &&
                                                  items.first_room.room_details
                                                  ? items.first_room.room_details
                                                    .room_name
                                                  : "",
                                                20,
                                                true
                                              )}
                                            </> : amount == 1 && items.privacy_setting && items.privacy_setting[0].val == "1" ?

                                              <>
                                                {trimTo(
                                                  items.first_room &&
                                                    items.first_room.room_details
                                                    ? items.first_room.room_details
                                                      .room_name
                                                    : "",
                                                  20,
                                                  true
                                                )}
                                              </> : ''
                                          }

                                        </p>
                                      ) : null
                                    ) : null}
                                  </span>
                                </div>
                              </Link>
                              {/* <a href="#" key="t-vertical" className={clsx({
                                  'disable-link': items.is_block
                                })}>
                                  <span className="sub-menu-avatar">
                                    {
                                      items && items.contact_user && items.contact_user.avatar && getBooleanStatus(items.contact_user.avatar && items.contact_user.avatar.visible_avatar ? items.contact_user.avatar.visible_avatar : 0) && items.contact_user.avatar.thumb ?
                                        <img src={items.contact_user.avatar.thumb} alt={items.contact_user.username} /> : (<span className="text-avatar">{getNameInitials(items.contact_user.username)}</span>)
                                    }
                                  </span>
                                  <div className="sub-menu-info">{items.customize_nickname && items.customize_nickname.nickname ? items.customize_nickname.nickname : items.contact_user.username} <span>
                                    <i className="oline-tag" style={{ backgroundColor: getStatusColor(items.contact_user.visible_status) }} /> {getAvailabiltyStatusText(items.contact_user.visible_status)}</span></div>
                                </a> */}
                            </li>
                          );
                        } else {
                          return null;
                        }
                      }
                    )
                    : null
                  // <li>
                  //   No user found
                  // </li>
                }
              </ul>
            </li>
            <li>
              <a href="#" className="has-arrow waves-effect">
                <i className="offline-icon-white" />
                <span key="t-layouts">
                  Offline{" "}
                  {leftMenuItemDetails &&
                    leftMenuItemDetails.offline_users &&
                    leftMenuItemDetails.offline_users.length
                    ? "(" + leftMenuItemDetails.offline_users.length + ")"
                    : ""}
                </span>
              </a>
              <ul className="sub-menu" aria-expanded="true">
                {leftMenuItemDetails &&
                  leftMenuItemDetails.offline_users &&
                  leftMenuItemDetails.offline_users.length ? (
                  leftMenuItemDetails.offline_users.map(
                    (items: any, index: number) => {
                      return (
                        <li key={index}>
                          <Link
                            to="#"
                            className={clsx({
                              "disable-link":
                                items.is_block || items.is_bloked_by_them,
                            })}
                            onContextMenu={(e) => onContextMenuClick(e, items)}
                            onDoubleClick={() => {
                              if (items.contact_user.visible_status == 3) {
                                return
                              }
                              else {
                                handleSendPm(items.contact_user.id)
                              }
                            }
                            }
                          >
                            <span className="sub-menu-avatar">
                              {items &&
                                items.contact_user &&
                                items.contact_user.avatar &&
                                getBooleanStatus(
                                  items.contact_user.avatar &&
                                    items.contact_user.avatar.visible_avatar
                                    ? items.contact_user.avatar.visible_avatar
                                    : 0
                                ) &&
                                items.contact_user.avatar.thumb ? (
                                <img
                                  src={items.contact_user.avatar.thumb}
                                  alt={items.contact_user.username}
                                />
                              ) : (
                                <span className="text-avatar">
                                  {getNameInitials(items.contact_user.username)}
                                </span>
                              )}
                            </span>
                            <div
                              className="sub-menu-info"
                              style={{
                                color: getSubscriptionColor(
                                  items &&
                                    items.contact_user &&
                                    items.contact_user.is_subscribed
                                    ? {
                                      ...items,
                                      subscription_info:
                                        items.contact_user.is_subscribed,
                                    }
                                    : null
                                ),
                              }}
                            >
                              {items.customize_nickname &&
                                items.customize_nickname.nickname
                                ? items.customize_nickname.nickname
                                : items.contact_user.username}
                              {items &&
                                items.badge_data &&
                                items.badge_data.current_badge &&
                                new Date(items.badge_data.expiry_date.replaceAll("-", "/")).getTime() >
                                new Date().getTime() ? (
                                <img
                                  src={
                                    items?.badge_data?.current_badge?.icon
                                      ?.original
                                  }
                                  height={20}
                                  width={20}
                                  className="m-2"
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <span>
                                <i className="offline-tag" />
                                {/* {getAvailabiltyStatusText(items.contact_user.visible_status)} */}
                                Offline
                              </span>
                              <span>
                                {items.privacy_setting &&
                                  items.privacy_setting.length ? (
                                  getPrivacySettingsRoomIamInOptions(
                                    items.privacy_setting.filter(
                                      (x: any) =>
                                        x.key === "show_room_i_am_in" &&
                                        x.field_type_details === "checkbox"
                                    )
                                  ) == "0" ? (
                                    <p
                                      className="left-menu-tooltip"
                                      title={items.contact_user.about}
                                    >
                                      {trimTo(
                                        items.contact_user.about,
                                        20,
                                        true
                                      )}
                                    </p>
                                  ) : (getPrivacySettingsRoomIamInOptions(
                                    items.privacy_setting.filter(
                                      (x: any) =>
                                        x.key ===
                                        "show_room_i_am_in_options" &&
                                        x.field_type_details === "radio"
                                    )
                                  ) == "1" &&
                                    items.is_in_contact) ||
                                    getPrivacySettingsRoomIamInOptions(
                                      items.privacy_setting.filter(
                                        (x: any) =>
                                          x.key ===
                                          "show_room_i_am_in_options" &&
                                          x.field_type_details === "radio"
                                      )
                                    ) == "2" ? (
                                    <p
                                      className="left-menu-tooltip"
                                      title={
                                        items.first_room &&
                                          items.first_room.room_details
                                          ? items.first_room.room_details
                                            .room_name
                                          : ""
                                      }
                                    >
                                      {trimTo(
                                        items.first_room &&
                                          items.first_room.room_details
                                          ? items.first_room.room_details
                                            .room_name
                                          : "",
                                        20,
                                        true
                                      )}
                                    </p>
                                  ) : null
                                ) : null}
                              </span>
                            </div>
                          </Link>
                        </li>
                      );
                    }
                  )
                ) : (
                  <li>No user found</li>
                )}
              </ul>
            </li>
            <li className="online-user-border">
              <p className="online-user">
                {/* {leftMenuItemDetails && (leftMenuItemDetails.total_online_user + 1)} */}
                {onlineUserCount} users online
              </p>
            </li>
          </MetisMenu>
        </div>
        <div className="profile-pic-check">
          <div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleShowHideProfilePicture(e.target.checked);
                }}
                className="custom-control-input"
                id="customControlInline2"
                checked={
                  userSelector &&
                    userSelector.visible_option &&
                    userSelector.visible_option.length
                    ? getBooleanStatus(
                      getValueFromArrayOfObject(
                        userSelector.visible_option,
                        "avatar_visible"
                      )
                    )
                    : false
                }
              />
              <label
                className="custom-control-label"
                htmlFor="customControlInline2"
              >
                Show user's profile pictures
              </label>
            </div>
            <div className="build-version-label">
              <label>
                Build Version:{" "}
                <code className="build-version">{APP_VERSION}</code>
              </label>
            </div>
          </div>
        </div>
        <div className="sidebar-banner-div">
          <SideBarBanner />
        </div>
      </div>

      <LeftBarPersonContextMenu
        getParams={contextMenuOperationParams}
        profile={membersData}
        isLoginUsers={isLoginUser}
      />

      <StatusToggleMenu />

      {showProfileModal && (
        <ViewProfileModal
          onClose={onViewProfileModalClose}
          shouldShow={showProfileModal}
          addToContactList={() => { }}
          isAddedToContactList={true}
          userId={selectedUserId}
        />
      )}

      {showStickerBuyModal ? (
        <StickerBuyModal
          onClose={handleOnCloseSticker}
          shouldShow={showStickerBuyModal}
          byModalType={"giftSendStickerBuy"} //ownStickerBuy or giftSendStickerBuy
          selectedContactList={selectedUser}
          type={""} // Make it blank
        />
      ) : null}

      {showCustomizedNicknameModal ? (
        <CustomizedNicknameModal
          onClose={customizedNicknameModalClose}
          onSuccess={() => { }}
          shouldShow={showCustomizedNicknameModal}
          fetchData={membersData}
        />
      ) : null}

      {/*Global Socket loader */}
      <SocketLoader />
    </React.Fragment>
  );
}

export default Sidebar;
