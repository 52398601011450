import React from "react";
import { Menu, Item, Separator, Submenu } from "react-contexify";
import { useAppGroupCategoryAction } from "src/_common/hooks/actions/groupCategory/appGroupCategoryActionHook";
import {
  CRYPTO_SECRET_KEY,
  getBooleanStatus,
  MENU_OPERATIONS,
} from "src/_config";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useGroupCategoryApi } from "src/_common/hooks/actions/groupCategory/appGroupCategoryApiHook";
import { toast } from "react-toastify";
import { useAppRoomDetailsSelector } from "src/_common/hooks/selectors/groupCategorySelector";
import { useAppPmWindowDetails } from "src/_common/hooks/selectors/pmWindowSelector";
import { usePmWindowApi } from "src/_common/hooks/actions/pmWindow/appPmWindowApiHook";
import { useAppPmWindowAction } from "src/_common/hooks/actions/pmWindow/appPmWindowActionHook";
// import GlobalFunction from "src/components/groupsCategory/common/chatWindow.tsx"
// import { colorHook } from "./";
const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const SettingContextMenu = (props: any) => {
  // const [saveDefault,setSaveDefault]=useState<boolean>(false)
  const { groupId, roomId } = useParams<any>();
  const { pmId } = useParams<any>();
  // const pm_id:any = parseInt(cryptr.decrypt(pmId));
  const groupCategoryAction = useAppGroupCategoryAction();
  const pmWindowAction = useAppPmWindowAction();
  const pmWindowDetailsSelector = useAppPmWindowDetails();
  const pmWindowApi = usePmWindowApi();
  const groupCategoryApi = useGroupCategoryApi();
  const roomDetailsSelector = useAppRoomDetailsSelector();
  // const history = useHistory();

  const handleItemClick = (e: any) => {};

  const getRoomDetails = () => {
    const params = {
      room_id: parseInt(cryptr.decrypt(roomId)),
    };
    groupCategoryApi.callGetRoomDetails(
      params,
      (message: string, resp: any) => {
        if (resp && resp.list && resp.list.length) {
        }
      },
      (message: string) => {
        // toast.error(message)
      }
    );
  };

  //Room User Settings menu
  const handleRoomSettings = (operation: string) => {
    const params = {
      room_id: parseInt(cryptr.decrypt(roomId)),
      key_name: operation,
      key_value:
        roomDetailsSelector &&
        roomDetailsSelector.user &&
        roomDetailsSelector.user.room_user_settings &&
        roomDetailsSelector.user.room_user_settings[operation]
          ? 0
          : 1,
    };

    groupCategoryApi.callChangeUserRoomSettings(
      params,
      (message: string, resp: any) => {
        if (resp) {
          //Call only if operation is timestamp
          if (operation === MENU_OPERATIONS.TIMESTAMP) {
            groupCategoryAction.roomChatTimestampToogle(
              getBooleanStatus(resp[operation])
            );
          }

          //Call only if operation is push to talk & lock mic
          if (
            operation === MENU_OPERATIONS.PUSH_TO_TALK ||
            operation === MENU_OPERATIONS.LOCK_MIC
          ) {
            groupCategoryAction.resetNormalUserMicHandleIsChanged(true);
          }

          //call only save default setting save called
          //  if (operation === MENU_OPERATIONS.SAVE_AS_DEFAULT_ROOM_SETTINGS) {
          //   groupCategoryAction.roomChatTimestampToogle(
          //     getBooleanStatus(resp[operation])
          //   );
          // }
          getRoomDetails();
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  //Api call for fetch save default room setting
  const handleSaveAsDefaultRoomSetting = (operation: string) => {
    const params1 = {
      room_id: parseInt(cryptr.decrypt(roomId)),
    };
    groupCategoryApi.callGetRoomDetails(
      params1,
      (message: string, resp: any) => {
        if (resp) {
          console.log(
            "9000000000000000  = ",
            (document.getElementsByClassName(
              "room-chat-content-editable"
            )[0].style.fontFamily = "")
          );
          var settingUpdateValu = roomDetailsSelector.user[operation];
          if (settingUpdateValu == 0) {
            settingUpdateValu = 1;
            document.getElementsByClassName(
              "room-chat-content-editable"
            )[0].style.fontFamily = resp.user.font_family;
            document.getElementsByClassName(
              "room-chat-content-editable"
            )[0].style.fontSize = resp.user.font_size;
            document.getElementsByClassName(
              "room-chat-content-editable"
            )[0].style.fontStyle = resp.user.font_style;
            document.getElementsByClassName(
              "room-chat-content-editable"
            )[0].style.textDecoration = resp.user.text_decoration;
            document.getElementsByClassName(
              "room-chat-content-editable"
            )[0].style.fontWeight = resp.user.font_weight;
            document.getElementsByClassName(
              "room-chat-content-editable"
            )[0].style.color = resp.user.font_color;
          } else if (settingUpdateValu == 1) {
            settingUpdateValu = 0;
            document.getElementsByClassName(
              "room-chat-content-editable"
            )[0].style = "";
            // document.getElementsByClassName(
            //   "room-chat-content-editable"
            // )[0].style = "";
          }

          const params = {
            room_id: parseInt(cryptr.decrypt(roomId)),
            key_name: operation,
            key_value: settingUpdateValu,
          };
          groupCategoryApi.callRoomUserWiseSaveDefaultSetting(
            params,
            (message: string, resp: any) => {
              if (resp) {
                getRoomDetails();
                console.log("Response from setting api =  ", resp);
              }
            },
            (message: string) => {
              // toast.error(message)
              console.log("ERROR = ", message);
            }
          );
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
    // console.log("===============set = ",roomDetailsSelector)
  };

  const handleResetDefaultSetting = (operation: string) => {
    // console.log(
    //   "Class === ",
    //   document.getElementsByClassName("room-chat-content-editable")[0].style
    // );
    const params1 = {
      room_id: parseInt(cryptr.decrypt(roomId)),
    };
    groupCategoryApi.callGetRoomDetails(
      params1,
      (message: string, resp: any) => {
        console.log("INSIDE RESET = ", resp.user.save_default_room_settings);
        const checkforreset = resp.user.save_default_room_settings;
        console.log("INSIDE RESET checkforreset = ", checkforreset);
        if (checkforreset == 1) {
          document.getElementsByClassName(
            "room-chat-content-editable"
          )[0].style = "";

          const params2 = {
            room_id: parseInt(cryptr.decrypt(roomId)),
            key_name: "save_default_room_settings",
            key_value: 0,
          };
          groupCategoryApi.callRoomUserWiseSaveDefaultSetting(
            params2,
            (message: string, resp: any) => {
              if (resp) {
                getRoomDetails();
                console.log("Response from setting api sdsd =  ", resp);
              }
            },
            (message: string) => {
              // toast.error(message)
              console.log("ERROR = ", message);
            }
          );

          const params = {
            room_id: parseInt(cryptr.decrypt(roomId)),
            // key_name: operation,
            // dynamicParams: dynamicParamsArray, // Array of objects containing key-value pairs
            font_color: "#000000",
            font_family: "Open Sans",
            font_size: 3,
            text_decoration: "normal",
            font_weight: "normal",
            font_style: "normal",
            // save_default_room_settings: 1,
          };
          groupCategoryApi.callRoomResetDefaultSetting(
            params,
            (message: string, resp: any) => {
              if (resp) {
                console.log("Response from reset api =  ", resp);
              }
            },
            (message: string) => {
              // toast.error(message)
              console.log("ERROR = ", message);
            }
          );
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  //Pm setting related Api call

  const getPmWindowDetails = () => {
    let params = {
      pm_id: parseInt(cryptr.decrypt(pmId)),
    };
    pmWindowApi.callGetPmsDetails(
      params,
      (message: string, resp: any) => {},
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const handlePmSetting = (operation: string) => {
    const params = {
      pm_id: parseInt(cryptr.decrypt(pmId)),
      key_name: operation,
      key_value:
        pmWindowDetailsSelector &&
        pmWindowDetailsSelector.pm_settings &&
        pmWindowDetailsSelector.pm_settings[operation]
          ? 0
          : 1,
    };
    pmWindowApi.callPmHeaderMenuSettingActionUpdate(
      params,
      (message: string, resp: any) => {
        if (resp) {
          //Call only if operation is timestamp
          if (operation === MENU_OPERATIONS.TIMESTAMP) {
            pmWindowAction.pmChatTimestampToogle(
              getBooleanStatus(resp[operation])
            );
          }

          getPmWindowDetails();
        }
      },
      (message: string) => {}
    );
  };

  const handleSaveAsDefaultPmSetting = () => {
    const params = {
      pm_id: parseInt(cryptr.decrypt(pmId)),
    };
    pmWindowApi.callPmHeaderMenuSaveDefaultSetting(
      params,
      (message: string, resp: any) => {
        if (resp) {
          getPmWindowDetails();
        }
      },
      (message: string) => {
        // toast.error(message)
      }
    );
  };

  const handleResetDefaultPmSetting = () => {
    const params = {
      pm_id: parseInt(cryptr.decrypt(pmId)),
    };
    pmWindowApi.callPmHeaderMenuResetDefaultSetting(
      params,
      (message: string, resp: any) => {
        if (resp) {
          getPmWindowDetails();
        }
      },
      (message: string) => {
        // toast.error(message)
      }
    );
  };

  return (
    <React.Fragment>
      <Menu id="room_header_menu_setting_id" className="header-click-menu">
        <Item
          onClick={(event) =>
            handleRoomSettings(MENU_OPERATIONS.AUTOSCROLL_TEXT)
          }
        >
          {roomDetailsSelector &&
          roomDetailsSelector.user &&
          roomDetailsSelector.user.room_user_settings &&
          roomDetailsSelector.user.room_user_settings[
            MENU_OPERATIONS.AUTOSCROLL_TEXT
          ] ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Autoscroll Text
        </Item>
        <Separator />
        {/* <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.SHOW_INCOMING_TEXT_WITH_FORMAT)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.SHOW_INCOMING_TEXT_WITH_FORMAT] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Show Incoming Text with Format</Item>
                <Separator /> */}
        <Item
          onClick={(event) => handleRoomSettings(MENU_OPERATIONS.TIMESTAMP)}
        >
          {roomDetailsSelector &&
          roomDetailsSelector.user &&
          roomDetailsSelector.user.room_user_settings &&
          roomDetailsSelector.user.room_user_settings[
            MENU_OPERATIONS.TIMESTAMP
          ] ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Timestamp
        </Item>

        <Item
          onClick={(event) =>
            handleRoomSettings(MENU_OPERATIONS.SORT_NICKNAME_ALPHABETICALLY)
          }
        >
          {roomDetailsSelector &&
          roomDetailsSelector.user &&
          roomDetailsSelector.user.room_user_settings &&
          roomDetailsSelector.user.room_user_settings[
            MENU_OPERATIONS.SORT_NICKNAME_ALPHABETICALLY
          ] ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Sort Nicknames Alphabetically
        </Item>
        <Separator />
        <Item
          onClick={(event) =>
            handleRoomSettings(MENU_OPERATIONS.DISABLE_DIG_SOUND)
          }
        >
          {roomDetailsSelector &&
          roomDetailsSelector.user &&
          roomDetailsSelector.user.room_user_settings &&
          roomDetailsSelector.user.room_user_settings[
            MENU_OPERATIONS.DISABLE_DIG_SOUND
          ] ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Disable Dig Sound
        </Item>

        <Submenu label="Notify Me When User">
          <Item
            onClick={(event) =>
              handleRoomSettings(MENU_OPERATIONS.NOTIFY_USER_JOIN_ROOM)
            }
          >
            {roomDetailsSelector &&
            roomDetailsSelector.user &&
            roomDetailsSelector.user.room_user_settings &&
            roomDetailsSelector.user.room_user_settings[
              MENU_OPERATIONS.NOTIFY_USER_JOIN_ROOM
            ] ? (
              <i className="fa fa-check" aria-hidden="true"></i>
            ) : null}
            Joins a room
          </Item>
          <Item
            onClick={(event) =>
              handleRoomSettings(MENU_OPERATIONS.NOTIFY_EXIT_ROOM)
            }
          >
            {roomDetailsSelector &&
            roomDetailsSelector.user &&
            roomDetailsSelector.user.room_user_settings &&
            roomDetailsSelector.user.room_user_settings[
              MENU_OPERATIONS.NOTIFY_EXIT_ROOM
            ] ? (
              <i className="fa fa-check" aria-hidden="true"></i>
            ) : null}
            Exits room
          </Item>
          <Item
            onClick={(event) =>
              handleRoomSettings(MENU_OPERATIONS.NOTIFY_START_WEBCAM)
            }
          >
            {roomDetailsSelector &&
            roomDetailsSelector.user &&
            roomDetailsSelector.user.room_user_settings &&
            roomDetailsSelector.user.room_user_settings[
              MENU_OPERATIONS.NOTIFY_START_WEBCAM
            ] ? (
              <i className="fa fa-check" aria-hidden="true"></i>
            ) : null}
            Starts Webcam
          </Item>
          <Item
            onClick={(event) =>
              handleRoomSettings(MENU_OPERATIONS.NOTIFY_STOP_WEBCAM)
            }
          >
            {roomDetailsSelector &&
            roomDetailsSelector.user &&
            roomDetailsSelector.user.room_user_settings &&
            roomDetailsSelector.user.room_user_settings[
              MENU_OPERATIONS.NOTIFY_STOP_WEBCAM
            ] ? (
              <i className="fa fa-check" aria-hidden="true"></i>
            ) : null}
            Stops Webcam
          </Item>
        </Submenu>
        {/* <Item onClick={(event) => handleItemClick(event)}>Font</Item> */}
        <Item
          onClick={(event) =>
            handleRoomSettings(MENU_OPERATIONS.CHANGE_ROOM_SCREEN)
          }
          disabled={true}
        >
          {roomDetailsSelector &&
          roomDetailsSelector.user &&
          roomDetailsSelector.user.room_user_settings &&
          roomDetailsSelector.user.room_user_settings[
            MENU_OPERATIONS.CHANGE_ROOM_SCREEN
          ] ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Change Room Skin
        </Item>
        {/* <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.MUTE_INCOMING_SOUND)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.MUTE_INCOMING_SOUND] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Mute Incoming Sound
                </Item> */}
        <Item
          onClick={() => {
            handleSaveAsDefaultRoomSetting(
              MENU_OPERATIONS.SAVE_AS_DEFAULT_ROOM_SETTINGS
            );
            // handleRoomSettings(MENU_OPERATIONS.SAVE_AS_DEFAULT_ROOM_SETTINGS)
          }}
        >
          {roomDetailsSelector &&
          roomDetailsSelector.user &&
          roomDetailsSelector.user.save_default_room_settings ==
            // roomDetailsSelector.user.room_user_settings &&
            // roomDetailsSelector.user.room_user_settings[
            // MENU_OPERATIONS.SAVE_AS_DEFAULT_ROOM_SETTINGS]
            // roomDetailsSelector.user.room_user_settings[
            //   MENU_OPERATIONS.SAVE_AS_DEFAULT_ROOM_SETTINGS]
            1 ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Save as Default Room Settings
        </Item>
        <Item
          onClick={() => {
            // handleResetDefaultSetting()
            handleResetDefaultSetting(
              MENU_OPERATIONS.RESET_DEFAULT_ROOM_SETTINGS
            );
          }}
        >
          Reset Default Settings
        </Item>
      </Menu>

      <Menu id="pm_header_settings_id" className="header-click-menu">
        <Item onClick={(event) => handlePmSetting("autoscrool_text")}>
          {pmWindowDetailsSelector &&
          pmWindowDetailsSelector.pm_settings &&
          pmWindowDetailsSelector.pm_settings.autoscrool_text ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Autoscroll Text Chat
        </Item>
        <Separator />
        {/* <Item
                    onClick={(event) => handlePmSetting(MENU_OPERATIONS.SHOW_INCOMING_TEXT_WITH_FORMAT)}>
                    {
                        pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings[MENU_OPERATIONS.SHOW_INCOMING_TEXT_WITH_FORMAT] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Show Incoming Text with Format</Item>
                <Separator /> */}
        <Item onClick={(event) => handlePmSetting(MENU_OPERATIONS.TIMESTAMP)}>
          {pmWindowDetailsSelector &&
          pmWindowDetailsSelector.pm_settings &&
          pmWindowDetailsSelector.pm_settings.timestamp ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Timestamp
        </Item>
        {/* <Item
                    onClick={(event) => handlePmSetting(MENU_OPERATIONS.SORT_NICKNAME_ALPHABETICALLY)}>
                    {
                        pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings[MENU_OPERATIONS.SORT_NICKNAME_ALPHABETICALLY] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Sort Nicknames Alphabetically
                </Item> */}
        <Separator />

        <Item onClick={(event) => handlePmSetting("disable_dig_sound")}>
          {pmWindowDetailsSelector &&
          pmWindowDetailsSelector.pm_settings &&
          pmWindowDetailsSelector.pm_settings.disable_dig_sound ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Disable Dig Sound
        </Item>

        {/* <Item onClick={(event) => handleItemClick(event)}>Font</Item> */}
        {/* <Separator /> */}
        <Item onClick={(event) => handleItemClick(event)}>PM Skins</Item>
        <Item
          onClick={(event) =>
            handlePmSetting(MENU_OPERATIONS.MUTE_INCOMING_SOUND)
          }
        >
          {pmWindowDetailsSelector &&
          pmWindowDetailsSelector.pm_settings &&
          pmWindowDetailsSelector.pm_settings[
            MENU_OPERATIONS.MUTE_INCOMING_SOUND
          ] ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : null}
          Mute Incoming Sound
        </Item>
        <Separator />
        <Item onClick={() => handleSaveAsDefaultPmSetting()}>
          Save as Default PM Settings
        </Item>
        <Item onClick={() => handleResetDefaultPmSetting()}>
          Reset Default PM Settings
        </Item>
      </Menu>
    </React.Fragment>
  );
};

export default SettingContextMenu;
