import React from 'react'
import { BuyCreditSuccessComponent } from 'src/components/groupsCategory/buyCredit/buyCreditSuccess';

export default function BuyCreditSuccess() {

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <BuyCreditSuccessComponent />
                </div>
            </div>
        </React.Fragment>
    )
}