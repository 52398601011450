import React, { useContext, useEffect, useState } from 'react';
import Slider from "react-slick";
import { usePmWindowApi } from 'src/_common/hooks/actions/pmWindow/appPmWindowApiHook';
import { useAppPmWindowDetails } from 'src/_common/hooks/selectors/pmWindowSelector';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { CRYPTO_SECRET_KEY, getNameInitials } from 'src/_config';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
    getSubscriptionColor,
} from "src/_config";
// import VideoCard from '../VideoCard';
// import { MediaSettingsContext } from 'src/containers/pm-room/pmRoom';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface OtherPmUsersProps {
    onWindowClose: () => void;
    streams: any[];
    // myLocalData: any;
    // participants: any;
    roomName: any;
    getPmWindowDetails: () => void;
}

const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    autoplay: false,
};

export default function OtherPmUsers({ onWindowClose, streams, 
    // myLocalData, participants,
     roomName, getPmWindowDetails }: OtherPmUsersProps) {

    const pmWindowDetailsSelector = useAppPmWindowDetails()
    const userSelector = useAppUserDetailsSelector()
    const pmWindowApi = usePmWindowApi()
    const history = useHistory()
    const { pmId } = useParams<any>();
    const pm_id: number = parseInt(cryptr.decrypt(pmId));

    const [user, setUser] = useState<any>(null);
    const [otherUsers, setOtherUsers] = useState<any>([]);
    // const mediaSettings = useContext<any>(MediaSettingsContext)

    const handleCloseOthersRoomPmUsersWindow = (e: React.MouseEvent) => {
        e.preventDefault()
        // onWindowClose()
        let params = {
            pm_id: pm_id
        }
        pmWindowApi.callExitPmWindow(params, (message: string, resp: any) => {
            history.push('dashboard')
        }, (message: string) => {

        })
    }

    // console.log("userELe", user)
    // console.log("userELe", pmWindowDetailsSelector)
    useEffect(() => {
        pmWindowDetailsSelector && userSelector && pmWindowDetailsSelector.users && pmWindowDetailsSelector.users.length && pmWindowDetailsSelector.users.filter((other: any) => {
            // console.log("userELe", other.user_id, userSelector.id)
            if (other.user_id !== userSelector?.id) {
                setOtherUsers((prev: any) => (
                    [
                        ...prev,
                        other
                    ]
                ))
            }

            if (other.user_id === userSelector?.id) {
                setUser(other);
            }
            // console.log("userELe","HERE--")
        })

        return () => {
            setOtherUsers([]);
            setUser(null);
        }
    }, [pmWindowDetailsSelector, userSelector])

    const [streamers, setStreamers] = useState([])

    // useEffect(() => {
    //     getPmWindowDetails()
    // }, [mediaSettings.cam])

    // console.log("pmWindowDetailsSelector", 'mediaSettings.cam',mediaSettings.cam)


    let [ownStream, SetOwnStream] = useState(false)

    // useEffect(() => {
    //     if (user && mediaSettings && mediaSettings.cam) {
    //         let find = mediaSettings.cam.find((ele: any) => ele.eventStreamId == 'localVideo' && ele.isCameraOn)
    //         SetOwnStream(find ? true : false)
    //     }
    // }, [user, mediaSettings.cam])

    // console.log('ownStream', ownStream)
    // useEffect(() => {
    //     if (otherUsers.length > 0) {
    //         let finalArr: any = []
    //         // console.log("otherRoomMembers otherUsers", otherUsers)
    //         otherUsers.forEach((user: any) => {
    //             // console.log("otherRoomMembers, user", user)
    //             if (user.stream) {
    //                 let findAny = mediaSettings.cam.find((ele: any) => ele.eventStreamId == user.stream.stream_id && ele.isCameraOn == true)
    //                 // console.log("roomDetails", "findAny", findAny)
    //                 console.log("otherRoomMembers", "findAny", findAny)

    //                 if (findAny) {
    //                     let findParticipantDetails = participants.find((ele: any) => ele.id = findAny.eventStreamId)
    //                     // console.log("roomDetails", "findParticipantDetails", findParticipantDetails)

    //                     console.log("otherRoomMembers", "findParticipantDetails", findParticipantDetails)

    //                     if (findParticipantDetails) {
    //                         user.participant_id = findParticipantDetails.id
    //                         user.participant_track = findParticipantDetails.track
    //                         user.participant_name = findParticipantDetails.name
    //                         finalArr.push(user)
    //                     }
    //                     else {
    //                         finalArr.push(user)
    //                     }
    //                 }
    //                 else {
    //                     finalArr.push(user)
    //                 }
    //             }
    //             else {
    //                 finalArr.push(user)
    //             }
    //         })
    //         // let streamers: any = mediaSettings.participants.filter((ele: any) => {
    //         //     findAny = mediaSettings.cam.find((camele: any) => (camele.eventStreamId == ele.id) && (camele.isCameraOn == true))
    //         //     return findAny ? true : false
    //         // })
    //         setStreamers(finalArr)
    //     }
    // }, [otherUsers, mediaSettings.participants, mediaSettings.cam])
    // console.log("mediaSettings.participants", mediaSettings.participants)
    // console.log("mediaSettings.cam", mediaSettings.cam)
    // console.log("otherRoomMembers streamers--", streamers)
    // console.log("otherUsers", otherUsers)


    const handleCloseOthersRoomPmUsersWindowFunction = (e: React.MouseEvent) => {
        e.preventDefault()
        // onWindowClose()
        let params = {
            pm_id: pm_id
        }
        pmWindowApi.callExitPmWindow(params, (message: string, resp: any) => {
            history.push('dashboard')
        }, (message: string) => {

        })
    }

    return (
        <div className="in-room-wrapper">
            {/* <a href="#"
                className="close-box"
                onClick={(e) => handleCloseOthersRoomPmUsersWindow(e)}
            >
                {/* <img src="/img/close-icon.png" alt="close" /> 
                {/* Exit 
                </a> */}
            <div className="in-room-content">
                <div className="room-list">
                    <Slider {...settings}>
                        {


                            // streamers.length > 0 &&
                            // streamers.map(({ participant_id, videoLabel, participant_track, participant_name }: any, index: any) => {
                            //     if (participant_id !== "localVideo") {
                            //         return (
                            //             <div className="unpinned" key={index}>
                            //                 <div className="single-video-container">
                            //                     <VideoCard
                            //                         id={participant_id}
                            //                         track={participant_track}
                            //                         autoPlay
                            //                         name={participant_name}
                            //                     />
                            //                 </div>
                            //             </div>
                            //         );
                            //     }

                            // })


                            (streamers && streamers.length > 0) ?
                                streamers.map((otherUser: any, index: number) => (
                                    <div className="room-box room-box-div" key={index}>
                                        <div id={'subscriber-' + otherUser.user_id}>
                                            {/* {
                                                console.log("otherRoomMembers otherUser",otherUser)
                                            } */}
                                            {
                                                otherUser.hasOwnProperty("participant_track") ? (
                                                    <>
                                                    </>
                                                    // <VideoCard
                                                    //     id={otherUser.participant_id}
                                                    //     track={otherUser.participant_track}
                                                    //     autoPlay
                                                    //     name={otherUser.participant_name}
                                                    // />
                                                ) :
                                                    (
                                                        (otherUser.user_info && otherUser.user_info.avatar && otherUser.user_info.avatar.thumb)
                                                            ?
                                                            <img src={otherUser.user_info.avatar.thumb} alt={otherUser.user_info.username} id={"img-" + otherUser.user_id} />
                                                            :
                                                            <p className='room-box-span'>{getNameInitials(otherUser?.user_info?.customize_nickname?.nickname || otherUser?.user_info?.username)}</p>
                                                    )
                                            }
                                        </div>
                                        <div className="room-box-name"
                                            style={{
                                                color: getSubscriptionColor(
                                                    otherUser &&
                                                        otherUser.user_info &&
                                                        otherUser.user_info.is_subscribed
                                                        ? {
                                                            ...otherUser,
                                                            subscription_info:
                                                                otherUser.user_info.is_subscribed,
                                                        }
                                                        : null
                                                ),
                                            }}
                                        >
                                            {otherUser.user_info.customize_nickname && otherUser.user_info.customize_nickname.nickname ? otherUser.user_info.customize_nickname.nickname : otherUser.user_info.username}
                                        </div>
                                    </div>
                                ))
                                :
                                ''
                        }
                        {
                            user ?
                                <div className="room-box room-box-div">
                                    <div id='camera-me'>
                                        {
                                            <>
                                                {/* <VideoCard
                                                    id="localVideo"
                                                    autoPlay
                                                    name="You"
                                                    muted
                                                    hidePin={true}
                                                    display={ownStream? "block": "hidden"}
                                                /> */}
                                                {
                                                    !ownStream ? (
                                                        (user.user_info && user.user_info.avatar && user.user_info.avatar.thumb)
                                                        ?
                                                        <img src={user.user_info.avatar.thumb} alt={user.user_info.username} id={"img-" + user.user_id} />
                                                        :
                                                        <p className='room-box-span'>{getNameInitials(user.user_info.username)}</p>
                                                    ): null
                                                }

                                            </>

                                        }
                                    </div>
                                    <div className="room-box-name"
                                        style={{
                                            color: getSubscriptionColor(
                                                user &&
                                                    user.user_info &&
                                                    user.user_info.is_subscribed
                                                    ? {
                                                        ...user,
                                                        subscription_info:
                                                            user.user_info.is_subscribed,
                                                    }
                                                    : null
                                            ),
                                        }}
                                    >{user.user_info.username}</div>
                                </div>
                                :
                                ''
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}